import { Box, Image, Link,
Flex, IconButton, Drawer, DrawerBody, DrawerOverlay, DrawerContent,
DrawerCloseButton, useDisclosure, VStack, Spacer, useBreakpointValue } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';
import NavButton from './NavButton';
import './fonts/Nohemi/Nohemi-Light.ttf';

function DesktopHeader() {
  return (
    <Box
      bg='#00cecb'
      color='black'
      width='100%'
      fontFamily='NohemiLight'
      fontSize='3xl'
      pl={{base:"3%", lg:"1%"}}
      pr={{base:"3%", lg:"1%"}}
      pt={"1%"}
    >
      <Flex
        alignItems="center"
        justify="space-between"
        width="100%"
        px={["3%", "3%", "3%"]}
        py={4}
      >
        {/* Logo */}
        <Box flexShrink={0} mr={[2, 4, 6]}>
          <Image
            src='https://cdn.testing-cylc-dfw.online/CYLC2023logo.jpg'
            alt='CYLC Logo'
            objectFit="contain"
            maxH="3.5rem"
            maxW="3.5rem"
          />
        </Box>

        {/* NavButton */}
        <Box flexShrink={0}>
          <NavButton />
        </Box>

        <Spacer />

        {/* Apply Now Button */}
        <Box
          flexShrink={0}
          backgroundColor={'#FFED68'}
          height={["2.5rem", "3rem", "3.5rem"]}
          width={['8em', '10em', '12em']}
          borderRadius={'40px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          fontFamily={'Nohemi'}
          ml={[2, 4, 6]}
        >
          <Link
            href='/application'
            textAlign="center"
          >
            Apply Now
          </Link>
        </Box>
      </Flex>
    </Box>
  );
}

function MobileHeader() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg='#00cecb'
      color='black'
      width='100%'
      fontFamily='NohemiLight'
      fontSize='2xl'
      pl={"2%"}
      pr={"2%"}
      pt={"2%"}
    >
      <Flex
        alignItems="center"
        justify="space-between"
        width="100%"
        px={["2%", "2%", "2%"]}
        py={3}
      >
        <Flex alignItems="center">
          {/* Hamburger Menu */}
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Open Menu"
            variant="ghost"
            color="current"
            mr={2}
            fontSize="1.5rem"
            onClick={onOpen}
          />

          {/* Logo */}
          <Box flexShrink={0} mr={2}>
            <Image
              src='./CYLC2023logo.jpg'
              alt='CYLC Logo'
              objectFit="contain"
              maxH="2.5rem"
              maxW="2.5rem"
            />
          </Box>
        </Flex>

        {/* Apply Now Button */}
        <Box
          flexShrink={0}
          backgroundColor={'#FFED68'}
          height={"2.5rem"}
          width={'7em'}
          borderRadius={'30px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          fontFamily={'Nohemi'}
        >
          <Link
            href='/application'
            textAlign="center"
          >
            Apply
          </Link>
        </Box>
      </Flex>

      {/* Drawer Menu */}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody
          bg={"#00cecb"}
          >
            <VStack
            align="start"
            spacing={4}
            mt={8}
            fontFamily={'Nohemi'}
            color={'#FFED68'}
            >
              <Link href="/" onClick={onClose}>Home</Link>
              <Link href="/details" onClick={onClose}>Details</Link>
              <Link href="/about" onClick={onClose}>About</Link>
              <Link href="/application" onClick={onClose}>Apply Now</Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

function Header() {
  const headerVariant = useBreakpointValue({ base: <MobileHeader/>, lg: <DesktopHeader/> });

  return headerVariant;
}

export default Header;