import React from 'react';
import { Box, VStack, HStack, Image, Heading, Text, AspectRatio, Button } from '@chakra-ui/react';
import "./fonts/fonts.css";

const FilmBlock = () => {
    const openOfficial = () => {
        window.open('https://www.youtube.com/@CYLCOfficial', '_blank', 'noopener,noreferrer');
      };

    const openStudios = () => {
        window.open('https://www.youtube.com/@CYLCStudios', '_blank', 'noopener,noreferrer');
      };

    return (
        <Box
        bg={"#C0B9DD"}
        overflow={"hidden"}
        >
            <HStack
            alignItems={"stretch"}
            spacing={0}
            height={{base:"30vh", lg:"100vh", "2xl":"100vh"}}
            >
                <Box
                width={"50%"}
                >
                    <Image
                    src='https://cdn.testing-cylc-dfw.online/Shipwreck-23.jpg'
                    height={"100%"}
                    objectFit={"cover"}
                    align={"top"}
                    objectPosition={"top"}
                    />
                </Box>
                <VStack
                width={"50%"}
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing={0}
                >
                    <Box
                    width="100%"
                    pb={"5%"}
                    >
                        <HStack
                        position={"relative"}
                        >
                            <Heading
                            fontFamily={"LemonMilk"}
                            fontSize={{ base: "6vw", lg: "12vh", "2xl": "11vh" }}
                            zIndex={0}
                            color="transparent" // Makes the text fill transparent
                            textStroke="0.2vw white" // For browsers that support Chakra's shorthand
                            sx={{
                            WebkitTextStroke: '0.2vw white', // Outline thickness and color
                            color: 'transparent', // Ensures the fill is transparent
                            }}
                            >
                                Lights, Camera,
                            </Heading>
                            <Text
                            fontFamily={'NohemiLight'}
                            fontSize={{base:"3vw", lg:"1.5vw", "2xl":"1.3vw"}}
                            align={"top"}
                            position={"absolute"}
                            top={"5%"}
                            right={"3%"}
                            >
                                (03) Film
                            </Text>
                        </HStack>
                        <Heading
                            fontFamily={"LemonMilk"}
                            fontSize={{ base: "6vw", lg: "12vh", "2xl": "11vh" }}
                            zIndex={0}
                            textAlign="right"
                            color="transparent" // Makes the text fill transparent
                            textStroke="0.2vw black" // For browsers that support Chakra's shorthand
                            sx={{
                            WebkitTextStroke: '0.2vw black', // Outline thickness and color
                            color: 'transparent', // Ensures the fill is transparent
                            }}
                        >
                            Action!
                        </Heading>
                    </Box>
                    <AspectRatio
                    ratio={1}
                    width={"90%"}
                    height={{lg:"35%", "2xl":"40%"}}
                    mt={{base: "-9%", lg:"-8%", "2xl":"-7.5%"}}
                    >
                        <iframe
                            src="https://www.youtube.com/embed/fwqN9MDuHzA?si=DWSVw20e8tIRdSNK&rel=0&modestbranding=1"
                            title="YouTube video player"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </AspectRatio>
                    <Text
                    fontFamily={"MeshedDisplay"}
                    fontSize={{base: "1vh", lg: "4vh", "2xl": "3vh"}}
                    pt={"2%"}
                    >
                        Capturing the moments that matter most.
                    </Text>
                    <HStack
                    spacing={{base: "10%", lg: "20%"}}
                    pr={"5%"}
                    pt={"2%"}
                    pl={"5%"}
                    >
                        <Button
                        borderRadius={"50px"}
                        p={"10%"}
                        w={{base:"16vw", lg:"15vw", "2xl":"12vw"}}
                        onClick={openOfficial}
                        fontSize={{base: "0.75vh", lg: "2vh", "2xl": "2vh"}}
                        fontFamily={"NohemiLight"}
                        >
                            CYLC Official
                        </Button>
                        <Button
                        borderRadius={"50px"}
                        p={"10%"}
                        w={{base:"16vw", lg:"15vw", "2xl":"12vw"}}
                        onClick={openStudios}
                        fontSize={{base: "0.75vh", lg: "2vh", "2xl": "2vh"}}
                        fontFamily={"NohemiLight"}
                        >
                            CYLC Studios
                        </Button>
                    </HStack>
                </VStack>
            </HStack>
        </Box>
    );
};

export default FilmBlock;