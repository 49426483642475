import React, { useEffect, useRef } from 'react';
import { Box, Image, Text, HStack} from '@chakra-ui/react';

const AutoScrollingGallery = ({ images, images2, speed = 30, categories }) => {
  const galleryRef = useRef(null);
  const galleryRef2 = useRef(null);

  useEffect(() => {
    const gallery1 = galleryRef.current;
    const gallery2 = galleryRef2.current;

    const cloneImages = (gallery, images) => {
      images.forEach((image) => {
        const img = document.createElement('img');
        img.src = image;
        img.style.width = 'auto';
        img.style.height = '100%';
        img.style.objectFit = 'cover';
        gallery.appendChild(img);
      });
    };

    cloneImages(gallery1, images);
    cloneImages(gallery2, images2);

    const scrollImages = () => {
      if (gallery1.scrollLeft >= gallery1.scrollWidth / 2) {
        gallery1.scrollLeft = 0;
      }
      if (gallery2.scrollLeft >= gallery2.scrollWidth / 2) {
        gallery2.scrollLeft = 0;
      }
      gallery1.scrollLeft += speed / 100;
      gallery2.scrollLeft += speed / 100;
      requestAnimationFrame(scrollImages);
    };

    scrollImages();
  }, [images, images2, speed]);

  const styles = {
    container: {
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    slide: {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      animation: `slide ${speed}s linear infinite`,
    },
    slideReverse: {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'nowrap',
      animation: `slideReverse ${speed}s linear infinite`,
    },
  };

  const keyframes = `
    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    @keyframes slideReverse {
      0% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(0);
      }
    }
  `;

  return (
    <Box
      bg="#FFFFEA"
      borderRadius={{base:"10px", lg:"20px"}}
      width={{base:"90%", lg:"94%", "2xl":"90%"}}
      height={{base:"40%", lg:"80%", "2xl":"90%"}}
      overflow="hidden"
      position="relative"
      justifyContent="center"
      paddingTop="3%"
      paddingBottom="10%"
    >
      <Text
      mb={"1%"}
      marginLeft={"3%"}
      fontSize={{base:"1.8vw", lg:"1.5vw", "2xl":"1.5vw"}}
      align={"left"}
      >
        (OO) Activities
      </Text>
      <HStack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={"3%"}
      padding={"3%"}
      whiteSpace={"nowrap"}
      fontSize={{base:"2.3vw", lg:"2.4vw", "2xl":"2.3vw"}}
      >
        {categories.map((category) => (
            <Text
            key={category}
            color={"black"}
            opacity={"90%"}
            fontFamily={'NohemiLight'}
            >
                {category}
            </Text>
        ))}
      </HStack>
      {/* First Gallery */}
      <Box
      style={styles.container}
      ref={galleryRef}
      >
        <style>{keyframes}</style>
        <Box className="logos-slide" style={styles.slide}>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              height={{base:"20vh", lg:"45vh"}}
              width={{base:"50vw", lg:"45vw"}}
              borderRadius={{base:"10px", lg:"20px"}}
              objectFit="cover"
              marginRight={"2%"}
              flexShrink={0}
            />
          ))}
        </Box>
      </Box>

      {/* Second Gallery */}
      <Box style={styles.container} ref={galleryRef2} paddingTop={"3%"}>
        <style>{keyframes}</style>
        <Box className="logos-slide" style={styles.slideReverse}>
          {images2.map((image, index) => (
            <Image
              key={index}
              src={image}
              height={{base:"20vh", lg:"45vh"}}
              width={{base:"50vw", lg:"45vw"}}
              alt={`Image ${index + 1}`}
              borderRadius={{base:"10px", lg:"20px"}}
              objectFit="cover"
              marginRight={"2%"}
              flexShrink={0}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AutoScrollingGallery;
