import "./fonts/fonts.css";
import {Box, HStack, Image, Link, VStack, Text, Heading} from '@chakra-ui/react'
import {ReactSocialMediaIcons} from 'react-social-media-icons';

function Footer(){
    return(
        <Box
        backgroundColor={"#FF5E5B"}
        height={"100%"}
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        >
            <VStack
            padding={{ base: "4%", lg: "3%", '2xl': "2%" }}
            justifyContent={"center"}
            alignContent={"center"}
            >
                <Text
                fontSize={{ base: "4vw", lg: "3vw", '2xl': "2vw" }}
                fontFamily={'Nohemi'}
                color={"white"}
                >
                    Interested?
                </Text>
                <Heading
                fontSize={{ base: "10vw", lg: "9vw", "2xl": "8vw" }}
                fontFamily={'Nohemi'}
                >
                    Learn More
                </Heading>
                <Box
                height={{base:"0.75px", lg:"1px"}}
                width={"90%"}
                backgroundColor={"#000000"}
                border={{base:"0.75px", lg:"1px"}}
                borderRadius={"40em"}
                >
                </Box>
                <HStack
                align={"start"}
                justifyItems={"center"}
                spacing={"20%"}
                paddingLeft={"10%"}
                paddingRight={"10%"}
                paddingTop={{base:"7%", "2xl":"5%"}}
                paddingBottom={{base:"7%", "2xl":"5%"}}
                fontFamily={'NohemiLight'}
                >
                    <VStack
                    alignItems={"left"}
                    maxWidth={"22%"}
                    color={"white"}
                    >
                        <Image src='https://cdn.testing-cylc-dfw.online/CYLC2023logo.jpg' alt='CYLC Logo' boxSize='30%' paddingLeft={"3%"} />
                        <Text
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        paddingLeft={"3%"}
                        >
                            Welcome to CYLC - Where future leaders are born.
                        </Text>
                        <HStack>
                            <ReactSocialMediaIcons
                            icon="instagram"
                            url="https://www.instagram.com/cylc.dfw"
                            backgroundColor="#FF5E5B"
                            borderColor="#FF5E5B"
                            size="3vw"
                            />
                            <ReactSocialMediaIcons
                            icon="facebook"
                            url="https://www.facebook.com/CYLCDFW"
                            backgroundColor="#FF5E5B"
                            borderColor="#FF5E5B"
                            size="3vw"
                            />
                            <ReactSocialMediaIcons
                            icon="youtube-play"
                            url="https://www.youtube.com/@CYLCOfficial"
                            backgroundColor="#FF5E5B"
                            borderColor="#FF5E5B"
                            size="3vw"
                            />
                        </HStack>
                    </VStack>
                    <VStack
                    alignItems={"left"}
                    >
                        <Text
                        fontSize={"2vw"}
                        fontFamily={'NohemiLight'}
                        color={"white"}
                        >
                            Navigation:
                        </Text>
                        <Link
                        href="/"
                        color={"#DCDCDC"}
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        >
                            Home
                        </Link>
                        <Link
                        href="/details"
                        color={"#DCDCDC"}
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        >
                            Details
                        </Link>
                        <Link
                        href="/about"
                        color={"#DCDCDC"}
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        >
                            About
                        </Link>
                    </VStack>
                    <VStack
                    alignItems={"left"}
                    >
                        <Text
                        fontSize={"2vw"}
                        color={"white"}
                        >
                            Contact Us:
                        </Text>
                        <Text
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        color={"#DCDCDC"}
                        >
                            Email: cylcdfw@gmail.com
                        </Text>
                        <Text
                        fontSize={{base:"1.5vw", "2xl":"1vw"}}
                        color={"#DCDCDC"}
                        >
                            Mail: PO Box 868156, Plano, TX 75086
                        </Text>
                    </VStack>
                </HStack>
                <Box
                height={{base:"0.75px", lg:"1px"}}
                width={"90%"}
                backgroundColor={"#000000"}
                border={{base:"0.75px", lg:"1px"}}
                borderRadius={"40em"}
                >
                </Box>
                <Text
                paddingTop={"3%"}
                fontFamily={'Nohemi'}
                fontSize={{base:"1.5vw", "2xl":"1vw"}}
                >
                    All rights reserved © 2024 www.cylcdfw.org
                </Text>
            </VStack>
        </Box>
    )
}

export default Footer;